import React, {Fragment} from 'react';
import Sticky from 'react-stickynode';
import {ThemeProvider} from 'styled-components';
import {saasTheme} from 'common/src/theme/saas';
import {ResetCSS} from 'common/src/assets/css/style';
import {ContentWrapper, GlobalStyle} from '../containers/Saas/saas.style';
import Navbar from '../containers/Saas/Navbar';
import BannerSection from '../containers/Saas/BannerSection';
import AboutUs from "../containers/Saas/AboutUs";
import FeatureSection from '../containers/Saas/FeatureSection';
import VisitorSection from '../containers/Saas/VisitorSection';
import {DrawerProvider} from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
import Footer from "../containers/Saas/Footer";
import ContactUsSection from "../containers/Saas/ContactUs";

export default () => {
  return (
      <ThemeProvider theme={saasTheme}>
        <Fragment>
          <SEO title="Claimmunity" />
          <ResetCSS />
          <GlobalStyle />
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <BannerSection />

            <AboutUs/>
            <FeatureSection/>
            <VisitorSection />
            <ContactUsSection/>
          </ContentWrapper>
          <Footer/>
        </Fragment>
      </ThemeProvider>
  );
};
