import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import NavbarWrapper from 'common/src/components/Navbar';
import Drawer from 'common/src/components/Drawer';
import Logo from 'common/src/components/UIElements/Logo';
import Box from 'common/src/components/Box';
import HamburgMenu from 'common/src/components/HamburgMenu';
import Container from 'common/src/components/UI/Container';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';

import LogoImage from 'common/src/assets/image/saas/claimmunity.jpg';

const  Navbar = ({ navbarStyle, logoStyle, row, menuWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        MENU_ITEMS {
          label
          path
          offset
          staticLink
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
      <NavbarWrapper {...navbarStyle}>
        <Container>
          <Box {...row}>
            <Logo
                href="#"
                logoSrc={LogoImage}
                title="Claimmunity"
                logoStyle={logoStyle}
            />
            <Box {...menuWrapper}>
              <ScrollSpyMenu
                  className="main_menu"
                  menuItems={Data.saasJson.MENU_ITEMS}
                  offset={-70}
              />
              <Drawer
                  width="420px"
                  placement="right"
                  drawerHandler={<HamburgMenu barColor="#eb4d4b" />}
                  open={state.isOpen}
                  toggleHandler={toggleHandler}
              >
                <ScrollSpyMenu
                    className="mobile_menu"
                    menuItems={Data.saasJson.MENU_ITEMS}
                    drawerClose={true}
                    offset={-40}
                />
              </Drawer>
            </Box>
          </Box>
        </Container>
      </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    className: 'hosting_navbar',
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    alignItems: 'right',
    justifyContent: [
      'space-between',
      'space-between',
      'space-between',
      // 'flex-end',
    ],
    width: '108%'
  },
  logoStyle: {
    maxWidth: '250px',
    mr: [0, 0, 0, '166px']
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryWithBg',
    minHeight: 'auto',
    height: `${1}`,
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
    textAlign: 'right'
  },
};

export default Navbar;
