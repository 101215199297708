import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, {BannerObject} from './bannerSection.style';
import {navigate} from "gatsby"

import HomePage from 'common/src/assets/image/saas/banner/homepage.jpg';
import Form from 'common/src/assets/image/saas/banner/form.jpg';

import Text from "common/src/components/Text";

const content = "A Risk Management & Insights \nPlatform for the Modern\nSupply Chain";
const text = "Freight incidents such as OS&Ds and Freight Claims can be confusing and manual. By " +
    "digitizing the process, Claimmunity provides transparency and visibility to all your freight incidents.";

const capture = "Capture Your Risk Data"
const captureText = "Record and understand the root cause" +
    " of your issues. Our custom fields will " +
    "allow you to track details of previous " +
    "and current freight incidents to mitigate " +
    "future ones."
const BannerSection = ({
                           row,
                           col,
                           title,
                           btnStyle,
                           description,
                           outlineBtnStyle,
                       }) => {
    const ButtonGroup = () => (
        <Fragment>
            <Button title="Sign Up" {...btnStyle} onClick={() => {
                navigate("https://platform.claimmunity.com/sign-up")
            }}/>
            <Button
                className="outlined"
                title="Contact Us"
                variant="outlined"
                onClick={() => {
                    navigate("#contact_us")
                }}
                {...outlineBtnStyle}
            />
        </Fragment>
    );
    return (<div>
            <BannerWrapper id="banner_section">
                <Container>
                    <Box className="row" {...row}>
                        <Box className="col" {...col}>
                            <FeatureBlock
                                title={
                                    <Heading
                                        content={content}
                                        {...title}
                                    />
                                }
                                description={
                                    <Text content={text} {...description} />
                                }
                                button={<ButtonGroup/>}
                                iconPosition={"top"}
                            />
                        </Box>

                        <Box className="col" {...col}>
                            <BannerObject>
                                <Image src={HomePage} alt="Home Page"/>
                            </BannerObject>
                        </Box>
                    </Box>
                </Container>
            </BannerWrapper>
            <BannerWrapper id="banner_section">
                <Container>
                    <Box className="row" {...row}>
                        <Box className="col" {...col}>
                            <FeatureBlock
                                title={
                                    <Heading
                                        content={capture}
                                        {...title}
                                    />
                                }
                                description={
                                    <Text className="break-word" content={captureText} {...description} />
                                }
                                iconPosition={"top"}
                            />
                        </Box>

                        <div>
                            <Box className="col" {...col}>
                                <BannerObject>
                                    <Image src={Form} alt="Form"/>
                                </BannerObject>
                            </Box>
                        </div>
                    </Box>
                </Container>
            </BannerWrapper>
        </div>
    )
};

BannerSection.propTypes = {
    title: PropTypes.object,
    btnStyle: PropTypes.object,
    description: PropTypes.object,
    contentStyle: PropTypes.object,
    discountText: PropTypes.object,
    discountAmount: PropTypes.object,
    outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
    row: {
        flexBox: true,
        flexWrap: 'wrap',
        ml: '-15px',
        mr: '-15px',
        alignItems: 'center'
    },
    col: {
        pr: '15px',
        pl: '15px',
        width: [1, '70%', '50%', '45%'],
    },
    title: {
        fontSize: ['22px', '34px', '30px', '30px'],
        fontWeight: '600',
        color: '#000000',
        letterSpacing: '-0.025em',
        mb: ['20px', '25px'],
        lineHeight: '1.5',
    },
    description: {
        fontSize: '16px',
        color: '#343d48cc',
        lineHeight: '1.75',
        mb: '0',
    },
    btnStyle: {
        minWidth: ['120px', '120px', '120px', '156px'],
        fontSize: ['13px', '14px'],
        fontWeight: '500',
        colors: 'primaryWithBg'
    },
    outlineBtnStyle: {
        minWidth: '156px',
        fontSize: '14px',
        fontWeight: '500',
        color: '#317ABB',
        ml: '18px',
    },
    span: {display: 'block'}
};

export default BannerSection;
