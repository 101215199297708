import styled from 'styled-components';
import semis from 'common/src/assets/image/saas/contactUs/Semis.jpg';

const ContactSectionWrapper = styled.section`
  background: url(${semis});
  height: 700px; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 60px;
`;

export default ContactSectionWrapper;