import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import VisitorSectionWrapper, {SectionObject} from './visitor.style';

import ImageOne from 'common/src/assets/image/saas/warehouse.jpg';

const VisitorSection = ({
                          title,
                          description,
                          textArea,
                          imageWrapper,
                          btnStyle,
                        }) => {
  return (
      <VisitorSectionWrapper id="visitorSection">
        <SectionObject>
          <Card className="dashboardWrapper" {...imageWrapper}>
            <Fade left>
              <Image src={ImageOne} alt="warehouse"   />
            </Fade>
          </Card>
        </SectionObject>
        <Container>
          <Box {...textArea}>
            <FeatureBlock
                title={
                  <Heading textAlign={"center"}
                           content="Who We Are"
                           {...title}
                  />
                }
                description={
                  <Text
                      content="Claimmunity was created by industry professionals for industry professionals.
                Our cofounders come with a wealth of knowledge and experience in Manufacturing, Distribution, Freight Brokerage, Transportation Management Systems, Software Architecture and Development.

                Our mission is to empower Shippers, Logistics Service Providers, and Carriers with modern day tools to evaluate risk, mitigate loss, improve operational efficiency, and retain profits."
                      {...description}
                  />
                }
                iconPosition={"top"}
            />
          </Box>
        </Container>
      </VisitorSectionWrapper>
  );
};

VisitorSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

VisitorSection.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0, '58%'],
  },
  imageWrapper: {
    ml: '80px',
    boxShadow: 'none',
  },
  title: {
    fontSize: ['22px', '34px', '30px', '30px'],
    fontWeight: '600',
    color: '#317ABB',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    lineHeight: '1.5',
  },
  description: {
    textAlign: 'left',
    fontSize: '15px',
    color: '#000000',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default VisitorSection;
