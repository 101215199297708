import React from 'react';
import Iframe from 'react-iframe'
import ContactSectionWrapper from './contactUsSection.style'
import './contactUsSection.css';

const ContactUsSection = ({iframeClass}, {iframeDiv}) => {

    return (
        <ContactSectionWrapper id="contact_us">
            <div className="iframeDiv" {...iframeDiv} align={"center"}>
                <Iframe scrolling={"no"} className="iframeClass"
                        url="https://platform.claimmunity.com/contact-us"
                />
            </div>
        </ContactSectionWrapper>
    )
};


export default ContactUsSection;