import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import FeatureSectionWrapper from './featureSection.style';
import Image from 'gatsby-image';

const FeatureSection = ({
                          row,
                          col,
                          sectionHeader,
                          sectionTitle,
                          featureTitle,
                          featureDescription,
                          iconStyle,
                          contentStyle,
                          blockWrapperStyle
                        }) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        Features {
          id
          title
          description
          icon
          thumbnail_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
      <FeatureSectionWrapper id="feature_section">
        <Container fullWidth={true}>
          <Box {...sectionHeader}>
            <Text content="Our Features" {...sectionTitle} />
          </Box>
          <Box className="row" {...row}>
            {Data.saasJson.Features.map((feature, index) => (
                <Box className="col" {...col} key={index}>
                  <FeatureBlock
                      icon={
                        <Image

                            fluid={
                              (feature.thumbnail_url !== null) | undefined
                                  ? feature.thumbnail_url.childImageSharp.fluid
                                  : {}
                            }
                            alt={`feature` + feature.id}
                        />

                      }
                      iconPosition={"top"}
                      wrapperStyle={blockWrapperStyle}
                      iconStyle={iconStyle}
                      contentStyle={contentStyle}
                      title={<Heading content={feature.title} {...featureTitle} />}
                      description={
                        <Text content={feature.description} {...featureDescription} />
                      }
                      className="saasFeature"
                  />
                </Box>
            ))}
          </Box>
        </Container>
      </FeatureSectionWrapper>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object
};

// FeatureSection default style
FeatureSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['10px', '10px', '10px', '40px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '30px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '30px'],
    fontWeight: '600',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '30px',
    mr: '-30px',
    alignItems: 'right'
  },
  // feature col default style
  col: {
    width: [1, 2/ 9, 2 / 9, 2 / 9, 2 / 9],
    border: 1,
    borderColor: '#F5F5F5',
    borderSpacing: '20px',
    backgroundColor: '#FFFFFF',
    display: 'block',
    mr: '20px'
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '30px', '40px'],
  },
  // feature icon default style
  iconStyle: {
    width: '50%',
    borderRadius: '50%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  // feature content default style
  contentStyle: {
    textAlign: 'middle',
    color: 'black'
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '600',
    color: '#0f2137',
    lineHeight: '1.5',
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.5',
    color: 'black'
  },
};

export default FeatureSection;
