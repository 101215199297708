import React from 'react';
import Fade from 'react-reveal/Fade';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import SectionWrapper, {Container, ImageWrapper, TextWrapper,} from './aboutUs.style';

import logo from 'common/src/assets/image/saas/aboutUs/task.png';

export const aboutData = {
    thumb_url: logo,
    title: 'Eliminate The Guesswork',
    text: 'Freight incidents can take hours, days, or even months to resolve. Eliminate the guesswork on what ' +
        'and when things need to get accomplished in order to resolve your OS&Ds and Freight Claims. ' +
        'Collaborate and share with internal and external stakeholders under one centralized platform.',
};

const AboutUs = () => {

    const setTitle = title => {
        return { __html: title };
    };

    return (
        <SectionWrapper id="aboutUs">
            <Container>
                <ImageWrapper>
                    <Fade left>
                        <img src = {logo} className="carousel_img" height="100%" width="100%"
                        />
                    </Fade>
                </ImageWrapper>
                <TextWrapper>
                    <Fade right>
                        <Heading as={"h1"} dangerouslySetInnerHTML={setTitle(aboutData.title)} />
                        <Text content={aboutData.text} />
                    </Fade>
                </TextWrapper>

            </Container>
        </SectionWrapper>
    );
};

export default AboutUs;
